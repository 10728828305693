<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'EmployeePolicy', 'Insurance')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>

		<div class="row">
			<div class="col-auto p-0">
				<h3>
					<b>Aftale</b>
				</h3>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-lg-4 col-12" v-if="insurances.length > 0">
				<AgreementDropdown v-model="agreementIndex" :options="insurances" :disabled="true" />
			</div>
			<div class="col-lg-4 col-12" v-if="insurances.length == 0">
				<h3>Der er ikke oprettet en forsikringsaftale for medarbejderen endnu / Gruppen har ikke en forsikringsaftale tilknyttet endnu</h3>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<template v-if="insurances.length > 0">
			<b-form @submit.prevent="onSubmit">
				<div class="row">
					<div class="col-auto p-0">
						<h3>
							<b>{{ $t('global.title.supplier') }}</b>
						</h3>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-4 col-12">
						<supplier v-model="standardInsurance.supplierId" :suppliers="suppliers" :disabled="true" />
					</div>
				</div>

				<template v-if="currentInsurance.supplierId !== ''">
					<div class="row">
						<div class="col-12">
							<hr />
						</div>
					</div>
				</template>

				<template v-if="currentInsurance.supplierId !== ''">
					<EmployeeInsurance
						v-model="currentInsurance"
						:standardInsurance="standardInsurance"
						v-bind:key="agreementIndex"
						:insuranceIndex="agreementIndex"
						:disabled="disabled"
					/>
				</template>

				<div class="row mb-5">
					<div class="col-3">
						<BaseActionButton
							class="w-100 btn-update"
							v-bind:clicked="clicked"
							v-bind:disabled="clicked"
							v-show="!disabled"
							type="submit"
							id="signupButton"
						>
							{{ $t('global.update') }}
						</BaseActionButton>
					</div>
				</div>
			</b-form>
		</template>
	</section>
</template>

<script>
import EmployeeInsurance from '@/modules/general/employee/components/EmployeeInsurance.vue'
import Swal from 'sweetalert2'
import AgreementDropdown from '@/modules/global/atomComponents/AgreementDropdown'

import Supplier from '@/modules/global/components/Supplier'
import SupplierPolicy from '@/modules/global/components/SupplierPolicyTemplate'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'

export default {
	name: 'CompanyEmployeeInsurance',
	components: {
		EmployeeInsurance,
		AgreementDropdown,
		Supplier,
		SupplierPolicy,
		InputDropdown,
	},
	async mounted() {
		this.$store.dispatch('companyVuex/getCompany', this.companyId)
		this.$parent.$emit('updateLoader')
		if (this.employeeGroup === undefined) {
			this.fireWarningMessage()
		}
	},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	methods: {
		changeAgreementIndex() {
			this.agreementIndex = this.insurances?.length - 1
		},
		fireWarningMessage() {
			Swal.fire({
				title: 'Gruppe er ikke valgt',
				text: 'Gruppe for medarbejderen er ikke valgt!',
				icon: 'warning',
			}).then((result) => {
				this.$router.push({ name: 'CompanyEmployeeCondition' })
			})
		},
		setEditable() {
			this.disabled = !this.disabled
		},
		async onSubmit() {
			this.clicked = true
			const policyId = this.employeePolicyId
			await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
				id: policyId,
				data: this.employeePolicy,
				companyId: this.companyId,
				employeeId: this.employeeId,
			})
			this.toast('Success', 'En medarbejder nu opdateret', true)
			this.clicked = false
		},
	},
	computed: {
		agreementIndex(){
			var insuranceId = this.$route.params.insuranceId
			var index = this.insurances.findIndex((insurance) => {
				return insurance.insuranceId === insuranceId
			})
			return index
		},
		employeeGroup() {
			return this.employeePolicy?.employeeCondition.group
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		groups() {
			return this.$store.getters['employeeVuex/company']?.groups
		},
		insurances() {
			return this.employeePolicy?.insurances.map((item) => {
				var companyInsurance = this.companyInsurances.find((companyInsurance) => {
					return companyInsurance.id === item.insuranceId
				})
				return { ...item, name: companyInsurance.insurance.name }
			})
		},
		currentInsurance() {
			return this.employeePolicy?.insurances[this.agreementIndex]
		},
		standardInsurance() {
			var standardInsurance = this.company?.insurances.find((companyInsurance) => {
				return companyInsurance.id === this.currentInsurance.insuranceId
			})
			return standardInsurance?.insurance
		},
		companyInsurances() {
			return this.company?.insurances
		},
		company() {
			return this.$store.getters['employeeVuex/company']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		employeePolicyId() {
			return this.employeePolicy?.id
		},
	},
}
</script>

<style></style>
