<template>
	<select class="form-control" v-model="inputValue" :disabled="disabled" @change="onChange">
		<option v-for="(groupTemplate, index) in groupTemplates" :key="groupTemplate.id" :value="groupTemplate.id" :selected="groupTemplate.id == value">
			{{ groupTemplate.name }}
		</option>
	</select>
</template>
<script>
export default {
	name: 'SupplierPolicyTemplate',
	props: {
		groupTemplates: Array,
		value: String,
		disabled: Boolean,
	},
	methods: {
		onChange(event) {
			this.$emit('supplierPolicyTemplateChanged', event.target.selectedIndex)
		},
	},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>
<style lang="scss"></style>
